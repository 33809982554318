<template>
        <Card class="card">
            <div class="top">
                <p class="title" >{{cardTitle}}
                    <Tooltip :content="cardTips" placement="top">
                        <span v-if='cardTips'><Icon type="ios-help" /></span> 
                    </Tooltip>
                </p>
                <p>{{cardTime}}</p>
            </div>
            <div class="bottom">
                <div class="left">
                    <p>
                        <span>{{cardData.num?cardData.num:0}}</span>{{cardData.unit}}
                    </p>
                </div>
                <div class="right">
                    <p>日<span :class="cardData.day<0?'red':'green'">{{cardData.day>0?'+':''}}{{ cardData.day?cardData.day.toFixed(2):0 }}%</span></p>
                    <p>周<span :class="cardData.week<0?'red':'green'">{{cardData.week>0?'+':''}}{{ cardData.week?cardData.week.toFixed(2):0 }}%</span></p>
                    <p>月<span :class="cardData.mounth<0?'red':'green'">{{cardData.mounth>0?'+':''}}{{ cardData.mounth?cardData.mounth.toFixed(2):0 }}%</span></p>
                </div>
            </div>
        </Card>
</template>

<script>
export default {
    props:{
        cardData:{
            type:Object,
            default:()=>{
                // return {
                //     num:0,
                //     unit:'',
                //     day:0,
                //     week:0,
                //     mounth:0
                // }
            }
        },
        cardTime:{
            type:String,
            default:()=>{
                return ''
            }
        },
        cardTitle:{
            type:String,
            default:()=>{
                return ''
            }
        },
        // tipsShow:{
        //     type:Boolean,
        //     default:()=>{
        //         return false
        //     }
        // },
        cardTips:{
            type:String,
            default:()=>{
                return ''
            }
        },
        
    },
    data () {
        return {
            
 
        }
    }
}
</script>
 
<style lang = "less" scoped>
.card{
    width: 30%;
    margin-bottom: 20px;
    padding: 20px;
    .top{
            .title{
                font-size:20px;
                span{
                    font-size:12px;
                    border:1px solid #888888;
                    border-radius: 50%;
                }
            }
        }
    .bottom{
        display: flex;
        justify-content: space-between;
        .left{
            position: relative;
            p{  
                margin-right: 10px;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 200px;
               span{
                    font-size: 26px;
                    margin-right:10px
                } 
            }
            
        }
        .right{
            span{
                padding-left: 30px;
            }
            .red {
                    color: red;
                }
            .green {
                color: green;
            }
        }
    }
}
</style>