<template>
  <LiefengContent>
    <template #title>访问趋势</template>
    <template v-slot:toolsbarLeft>
        <DatePicker 
            :options="options"
            @on-change='changeTime'
            type="daterange" 
            show-week-numbers placement="bottom-end" 
            placeholder="选择日期" 
            class="picker"
            v-model="timeData"
            :clearable='false'
            :disabled='disabled'
        ></DatePicker>
    </template>
    <template v-slot:toolsbarRight>
        <Timedropdown :disabled='disabled' @changeTime='getTime' ></Timedropdown>
        <Button @click="$router.push('/appletsurvey')">概况</Button>
        <Button @click="$router.push('/accesstrends')" type="primary">访问趋势</Button>
        <Button @click="$router.push('/accessspread')" >访问分布</Button>
        <Button @click="$router.push('/savedata')">数据展示</Button>
        <!-- <Button @click="$router.push('/userportrait')" >用户画像</Button> -->
    </template>
    <template #contentArea>
      <div class="box">
        <CardItem cardTitle="日打开次数" :cardTime="cardTime"  :cardData='openCard'></CardItem>
        <!-- <CardItem cardTitle="周打开次数" :cardTime="cardTime" cardTips='近7天打开次数' :cardData='openCardw'></CardItem>
        <CardItem cardTitle="月打开次数" :cardTime="cardTime" cardTips='近30天打开次数' :cardData='openCardm'></CardItem> -->
        
        <CardItem cardTitle="日访问人数" :cardTime="cardTime" :cardData='totalUvCard'></CardItem>
        <!-- <CardItem cardTitle="周访问人数" :cardTime="cardTime" cardTips='近7天访问人数(去重)' :cardData='totalUvCardw'></CardItem>
        <CardItem cardTitle="月访问人数" :cardTime="cardTime" cardTips='近30天访问人数(去重)' :cardData='totalUvCardm'></CardItem> -->

        <CardItem cardTitle="访问次数" :cardTime="cardTime" :cardData='totalPvCard'></CardItem>
        <CardItem cardTitle="日新增用户" :cardTime="cardTime" :cardData='newUvCard'></CardItem>
        <CardItem cardTitle="人均停留时长" :cardTime="cardTime" :cardData='stayUserCard'></CardItem>
        <CardItem cardTitle="次均停留时长" :cardTime="cardTime" :cardData='stayPvCard'></CardItem>
        <CardItem cardTitle="次均访问深度" :cardTime="cardTime" :cardData='uvRangeCard'></CardItem>
      </div>

      <Card>
        <div class="btn_list">
          <Button @click="onType(0)" :type="index==0?'primary':''">日打开次数</Button>
          <Button @click="onType(1)" :type="index==1?'primary':''">周打开次数</Button>
          <Button @click="onType(2)" :type="index==2?'primary':''">月打开次数</Button>
          <Button @click="onType(3)" :type="index==3?'primary':''">日访问人数</Button>
          <Button @click="onType(4)" :type="index==4?'primary':''">周访问人数</Button>
          <Button @click="onType(5)" :type="index==5?'primary':''">月访问人数</Button>
          
          <Button @click="onType(10)" :type="index==10?'primary':''">日新增人数</Button>
          <Button @click="onType(11)" :type="index==11?'primary':''">周新增人数</Button>
          <Button @click="onType(12)" :type="index==12?'primary':''">月新增人数</Button>

          <Button @click="onType(6)" :type="index==6?'primary':''">访问次数</Button>
          <Button @click="onType(7)" :type="index==7?'primary':''">人均停留时长</Button>
          <Button @click="onType(8)" :type="index==8?'primary':''">次均停留时长</Button>
          <Button @click="onType(9)" :type="index==9?'primary':''">次均访问深度</Button>
        </div>
        <Vline style="margin-top:20px" :data="lineData"></Vline>
      </Card>
      
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "./children/LiefengContent"
import Timedropdown from './children/timedropdown'
import  CardItem from  './children/carditem'
import Vline from "v-charts/lib/line.common"
export default {
  data() {
    return {
      disabled:false,
      index:0,
      options: {
        disabledDate(date) {
          return date && date.valueOf() > new Date() - 1000 * 60 * 60 * 24 * 1;
        }
      },
      timeData:[this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*7), "yyyy-MM-dd"),this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd")],
      cardTime:'',
      openCard:{},
      openCardw:{},
      openCardm:{},
      totalPvCard:{},
      totalUvCard:{},
      totalUvCardw:{},
      totalUvCardm:{},
      newUvCard:{},
      stayUserCard:{},
      stayPvCard:{},
      uvRangeCard:{},

      lineData:{},
      openDatad: {
        columns: ["时间", "打开次数"],
        rows: [
        ],
      },
      openDataw: {
        columns: ["时间", "打开次数"],
        rows: [
        ],
      },
      openDatam: {
        columns: ["时间", "打开次数"],
        rows: [
        ],
      },
      totalPvData:{
        columns: ["时间", "访问次数"],
        rows: [
        ],
      },
      totalUvDatad:{
        columns: ["时间", "访问人数"],
        rows: [
        ],
      },
      totalUvDataw:{
        columns: ["时间", "访问人数"],
        rows: [
        ],
      },
      totalUvDatam:{
        columns: ["时间", "访问人数"],
        rows: [
        ],
      },
      stayUserData:{
        columns: ["时间", "时长(s)"],
        rows: [
        ],
      },
      stayPvData:{
        columns: ["时间", "时长(s)"],
        rows: [
        ],
      },
      uvRangeData:{
        columns: ["时间", "访问深度"],
        rows: [
        ],
      },
      newUvDatad:{
        columns: ["时间", "新增人数"],
        rows: [
        ],
      },
      newUvDataw:{
        columns: ["时间", "新增人数"],
        rows: [
        ],
      },
      newUvDatam:{
        columns: ["时间", "新增人数"],
        rows: [
        ],
      },

    }
  },
  created(){
    this.getData()
    this.lineData = this.openDatad
  },
  methods: {
    getTime(val){
      this.timeData = val
      this.getData()
    },
    getData(){
      this.cardTime = this.timeData[1];
      this.openDatad.rows = []
      this.openDataw.rows = []
      this.openDatam.rows = []
      this.totalPvData.rows = []
      this.totalUvDatad.rows = []
      this.totalUvDataw.rows = []
      this.totalUvDatam.rows = []
      this. stayUserData.rows = []
      this.stayPvData.rows = []
      this.uvRangeData.rows = []
      this.newUvDatad.rows = []
      this.newUvDataw.rows = []
      this.newUvDatam.rows = []
      this.$Message.loading({
        content: "数据正在加载中",
        duration: 0
      });
      this.disabled = true
        this.$get('/statistic/api/symanage/pc/weappStat/getAllData',
        {
            startDate:this.timeData[0],
            endDate:this.timeData[1]
        }).then(res => {
          this.disabled = false
          this.$Message.destroy();
            if(res.code == 200 && res.data){
                let data = res.data
                this.openCard = {
                    num:data.weappDailyStatTotalVoList[data.weappDailyStatTotalVoList.length - 1].openNum,
                    unit:'次',
                    day:data.dayHb.openNum*100,
                    week:data.weekHb.openNum*100,
                    mounth:data.monthHb.openNum*100
                }
                // this.openCardw = {
                //     num:data.weekTotals[data.weekTotals.length - 1].openNum,
                //     unit:'次',
                //     day:data.dayHb.openNum*100,
                //     week:data.weekHb.openNum*100,
                //     mounth:data.monthHb.openNum*100
                // }
                // this.openCardm = {
                //     num:data.monthTotals[data.monthTotals.length - 1].openNum,
                //     unit:'次',
                //     day:data.dayHb.openNum*100,
                //     week:data.weekHb.openNum*100,
                //     mounth:data.monthHb.openNum*100
                // }
                this.totalPvCard = {
                    num:data.weappDailyStatTotalVoList[data.weappDailyStatTotalVoList.length - 1].totalPv,
                    unit:'次',
                    day:data.dayHb.totalPv*100,
                    week:data.weekHb.totalPv*100,
                    mounth:data.monthHb.totalPv*100
                }
                this.totalUvCard = {
                    num:data.weappDailyStatTotalVoList[data.weappDailyStatTotalVoList.length - 1].totalUv,
                    unit:'人',
                    day:data.dayHb.totalUv*100,
                    week:data.weekHb.totalUv*100,
                    mounth:data.monthHb.totalUv*100
                }
                // this.totalUvCardw = {
                //     num:data.weekTotals[data.weekTotals.length - 1].totalUv,
                //     unit:'人',
                //     day:data.dayHb.totalUv*100,
                //     week:data.weekHb.totalUv*100,
                //     mounth:data.monthHb.totalUv*100
                // }
                // this.totalUvCardm = {
                //     num:data.monthTotals[data.monthTotals.length - 1].totalUv,
                //     unit:'人',
                //     day:data.dayHb.totalUv*100,
                //     week:data.weekHb.totalUv*100,
                //     mounth:data.monthHb.totalUv*100
                // }
                this.newUvCard = {
                  num:data.weappDailyStatTotalVoList[data.weappDailyStatTotalVoList.length - 1].newUv,
                  unit:'人',
                  day:data.dayHb.newUv*100,
                  week:data.weekHb.newUv*100,
                  mounth:data.monthHb.newUv*100
                }
                this.stayUserCard = {
                  num:data.weappDailyStatTotalVoList[data.weappDailyStatTotalVoList.length - 1].stayUser.toFixed(2),
                    unit:'秒',
                    day:data.dayHb.stayUser*100,
                    week:data.weekHb.stayUser*100,
                    mounth:data.monthHb.stayUser*100
                }
                this.stayPvCard = {
                  num:data.weappDailyStatTotalVoList[data.weappDailyStatTotalVoList.length - 1].stayPv.toFixed(2),
                    unit:'秒',
                    day:data.dayHb.stayPv*100,
                    week:data.weekHb.stayPv*100,
                    mounth:data.monthHb.stayPv*100
                }
                this.uvRangeCard = {
                  num:data.weappDailyStatTotalVoList[data.weappDailyStatTotalVoList.length - 1].uvRange.toFixed(2),
                    unit:' ',
                    day:data.dayHb.uvRange*100,
                    week:data.weekHb.uvRange*100,
                    mounth:data.monthHb.uvRange*100
                }

                data.weappDailyStatTotalVoList.map(item => {
                  this.openDatad.rows.push(
                      {时间:item.statDate,打开次数:item.openNum}
                  ) 
                  this.totalPvData.rows.push(
                      {时间:item.statDate,访问次数:item.totalPv}
                  ) 
                  this.totalUvDatad.rows.push(
                      {时间:item.statDate,访问人数:item.totalUv}
                  ) 
                  this.stayUserData.rows.push(
                      {时间:item.statDate,'时长(s)':item.stayUser}
                  ) 
                  this.stayPvData.rows.push(
                      {时间:item.statDate,'时长(s)':item.stayPv}
                  ) 
                  this.uvRangeData.rows.push(
                      {时间:item.statDate,访问深度:item.uvRange}
                  ) 
                  this.newUvDatad.rows.push(
                      {时间:item.statDate,新增人数:item.newUv}
                  ) 
                })
                data.weekTotals.map(item => {
                  this.openDataw.rows.push(
                      {时间:item.statDate,打开次数:item.openNum}
                  )
                  this.totalUvDataw.rows.push(
                      {时间:item.statDate,访问人数:item.totalUv}
                  ) 
                  this.newUvDataw.rows.push(
                      {时间:item.statDate,新增人数:item.newUv}
                  ) 
                })
                data.monthTotals.map(item => {
                  this.openDatam.rows.push(
                      {时间:item.statDate,打开次数:item.openNum}
                  )
                  this.totalUvDatam.rows.push(
                      {时间:item.statDate,访问人数:item.totalUv}
                  )
                  this.newUvDatam.rows.push(
                      {时间:item.statDate,新增人数:item.newUv}
                  ) 
                })
            }else{
            this.$Message.error({
              content:'获取数据失败',
              background:true
            })
          }
        })
    },
    changeTime(val){
      console.log(val)
      this.timeData[0] = val[0]
      this.timeData[1] = val[1]
      console.log(this.timeData)
      this.getData()
    },
    onType(index){
      this.index = index
      if(index == 0){
        this.lineData = this.openDatad
      }else if(index == 1){
        this.lineData = this.openDataw
      }else if(index == 2){
        this.lineData = this.openDatam
      }else if(index == 3){
        this.lineData = this.totalUvDatad
      }else if(index == 4){
        this.lineData = this.totalUvDataw
      }else if(index == 5){
        this.lineData = this.totalUvDatam
      }else if(index == 6){
        this.lineData = this.totalPvData
      }else if(index == 7){
        this.lineData = this.stayUserData
      }else if(index == 8){
        this.lineData = this.stayPvData
      }else if(index == 9){
        this.lineData = this.uvRangeData
      }else if(index == 10){
        this.lineData = this.newUvDatad
      }else if(index == 11){
        this.lineData = this.newUvDataw
      }else{
        this.lineData = this.newUvDatam
      }
      
    }
  },
  components: {
    LiefengContent,
    Timedropdown,
    CardItem,
    Vline
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-card-head {
  border: none;
}
.btn_list{
  
  button{
    margin:0 10px 5px 0;
  }
}
.picker{
    position: absolute;
    right: 0;
}
.box{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
</style>